


















































import userService from '@/shared/services/userService'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { downloadFile } from '@/shared/helpers'
import i18n from '@/i18n'

export default Vue.extend({
  name: 'SignsView',

  components: {},

  data() {
    return {
      canCreateReports: false,
      loadingAccessDenied: false,
      loadingDontSwitchOn: false,
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
    }),
    userIsSystemAdmin() {
      return userService.userIsSystemAdmin()
    },
  },
  async mounted() {
    this.canCreateReports = await userService.canCreateReports()
  },
  methods: {
    async getWarningSignAsPDF() {
      this.loadingAccessDenied = true
      const name = Vue.filter('fullNameOfUser')(this.account)
      const result = await this.axios.post(
        '/api/getSignAsPDF',
        {
          component: 'access-denied-sign',
          name: name,
          logo: this.company && this.company ? this.company.metadata.logo : '',
          lang: i18n.locale,
        },
        { responseType: 'blob' },
      )
      const blob = new Blob([result.data], { type: 'application/pdf' })
      downloadFile(blob, this.$t('access-denied-sign') + '.pdf', 'application/pdf')
      this.loadingAccessDenied = false
    },
    async getDontSwitchOnSignAsPDF() {
      this.loadingDontSwitchOn = true
      const name = Vue.filter('fullNameOfUser')(this.account)
      const result = await this.axios.post(
        '/api/getSignAsPDF',
        {
          component: 'dont-switch-on-sign',
          name: name,
          logo: this.company ? this.company.metadata.logo : '',
          lang: i18n.locale,
        },
        { responseType: 'blob' },
      )
      const blob = new Blob([result.data], { type: 'application/pdf' })
      downloadFile(blob, this.$t('dont-switch-on-sign') + '.pdf', 'application/pdf')
      this.loadingDontSwitchOn = false
    },
  },
})
